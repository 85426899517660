import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Row, Col, ProductCard } from '../components/global/'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'

const Webinars = () => (
  <Layout includeNotice={false}>
    <SEO title="Webinars" />
    <div className="container container-last">
      <h1>Auto/Truck Electrical Training Webinars</h1>
      <p className="boost">
        Webinars focus on electrical &amp; electronic training topics relating to auto / truck /
        H.D. circuit troubleshooting &amp; repair.
      </p>
      <p>
        <a href="/webinars/w-001">Webinar W001 is now free to watch!</a>
      </p>
      <p>
        Vince presents essential electrical-electronics training topics from his vast archives of
        electrical-electronics training materials. Seminars are conducted live as a Power Point
        presentation narrated by Vince. Each webinar focuses on a particular topic covering the
        troubleshooting procedures of various auto-truck-H.D. electrical-electronic circuits.
        Webinars follow a logical progression of an electrical-electronics training program.
      </p>
      <p>
        Attendees print out a copy of the webinar handout to take notes as Vince explains the
        webinar material in a Power Point style class. Attendees keep their handouts and notes to
        follow through hands-on application of the training procedures presented.
      </p>
      <p>
        Webinars are first presented live, are no more than 1.0 hour in length and recorded.
        Technicians may attend the first live presentation. A recorded webinar is then edited for
        future training purposes. Q&amp;A sessions follow each live seminar but are not included in
        the edited recording.{' '}
      </p>
      <p>
        Each completed webinar may be purchased for future training sessions. The link to watch a
        purchased webinar is good for 7 days and may be watched as many times as desired during the
        7-day period.
      </p>
      <p>
        A sign-up link for each live webinar is sent by email to everyone on{' '}
        <a href="https://visitor.r20.constantcontact.com/manage/optin?v=001Re2XAHrK4vlFTRaxxp_JhdFSCGwu-S1pEYKOLZEwrtaMpBVDAeq-THxG1RWiVwEpEk-yISZpMOQ%3D">
          Veejer's Newsletter Email List
        </a>
        . After registration, a confirmation email is sent which contains a .pdf copy of the webinar
        handout that is printed out to take notes during the webinar.
      </p>
      <p>
        <a href="https://visitor.r20.constantcontact.com/manage/optin?v=001Re2XAHrK4vlFTRaxxp_JhdFSCGwu-S1pEYKOLZEwrtaMpBVDAeq-THxG1RWiVwEpEk-yISZpMOQ%3D">
          Sign Up Here
        </a>{' '}
        to join the free newsletter email list if you are not already receiving Veejer’s newsletter.
        Once signed up you will automatically receive announcements when new webinars are scheduled
        with a link to attend a live presentation.
      </p>
      <h2>Idea for Fleet Managers &amp; Shop Owners</h2>
      <p>
        Use these webinars to give employees short one-hour sessions of electrical-electronic
        troubleshooting training. Purchase a webinar and print out copies of the webinar handout for
        each tech. Then host a one-hour shop meeting and watch the webinar as techs follow along in
        their copy of the handout and take notes. The webinar link remains active for 7 days. Show
        the webinar numerous times to pick up missed points. It’s like having Vince as a guest
        electrical trainer in your shop for a week.
      </p>
      <h2>Idea for Teachers in Tech Schools</h2>
      <p>
        Use these webinars to give your students short one-hour bursts of electrical-electronic
        troubleshooting training. Purchase a webinar and print out copies of the webinar handout for
        each student. Watch the webinar in the classroom as students follow along in their copy of
        the handout. The webinar link remains active for 7 days. Show the webinar numerous times to
        pick up missed points. It’s like having Vince as a guest speaker in your classroom for a
        week.
      </p>
      <p>
        Below are links to purchase a webinar after the original presentation airs and view it for
        seven days. You will also receive a copy of the handout which you can print out for the
        webinar. You don’t have to miss these vital and unique electronic-electrical training
        opportunities.
      </p>
      <hr />
      <Row justifyContent="center">
        <Col width="75%">
          <h2>Past Webinars</h2>
          <ProductCard
            type="secondary"
            title="Webinar 008: Relays, Part 2 of (2)"
            description="How to Troubleshoot Relay and Solid-State Relay Circuits."
            url="/webinars/w-008"
          />
        </Col>
      </Row>
      <Row justifyContent="center">
        <Col width="75%">
          <ProductCard
            type="secondary"
            title="Webinar 007: Relays, Part 1 of (2)"
            description="What They Never Told You About Mechanical and Solid-State Relays That You Need to Know. In this webinar we discuss mechanical relay operation and common misunderstandings. Then solid-state relays (SSRs) are explained, what you need to know about SSRs, what they are and in what ways they are used. This prepares technicians for Webinar W008 demonstrating step-by-step troubleshooting procedures for relays and SSRs."
            url="/webinars/w-007"
          />
        </Col>
      </Row>
      <Row justifyContent="center">
        <Col width="75%">
          <ProductCard
            type="secondary"
            title="Webinar 006: Understanding &amp; Troubleshooting Solenoid Circuits (Part 2 of 2)"
            description="Picking up the story of solenoids from Webinar W005 we now discuss on-board computer control of solenoid circuits."
            url="/webinars/w-006"
          />
        </Col>
      </Row>
      <Row justifyContent="center">
        <Col width="75%">
          <ProductCard
            type="secondary"
            title="Webinar 005: Understanding &amp; Troubleshooting Solenoid Circuits (Part 1 of 2)"
            description="Solenoids or coils are electrical components that are activated when an electron current passes through it. Solenoids are used to control electrical circuits, mechanical devices and to direct the path of hydraulic fluid in vehicle systems."
            url="/webinars/w-005"
          />
        </Col>
      </Row>
      <Row justifyContent="center">
        <Col width="75%">
          <ProductCard
            type="secondary"
            title="Webinar 004: How To Troubleshoot Any Electrical Circuit on Any Vehicle (Part 2 of 2)"
            description="Webinar Part 2 of 2 parts covers the last three common electrical problems that could occur in any vehicle electrical-electronic circuit."
            url="/webinars/w-004"
          />
        </Col>
      </Row>
      <Row justifyContent="center">
        <Col width="75%">
          <ProductCard
            type="secondary"
            title="Webinar 003: How To Troubleshoot Any Electrical Circuit on Any Vehicle (Part 1 of 2)"
            description="There are eight ways a vehicle electrical circuit can fail. Any electrical circuit on any
            vehicle can only experience eight possible basic circuit problems. In this Webinar, W003
            Part 1, we explain and illustrate the first five ways a circuit can fail and the
            troubleshooting procedure that reveals what the problem is in about one minute or less."
            url="/webinars/w-003"
          />
        </Col>
      </Row>
      <Row justifyContent="center">
        <Col width="75%">
          <ProductCard
            type="secondary"
            title="Webinar 002: The Anatomy of an Electrical Circuit"
            description="Analyze the essential anatomy of vehicle electrical circuits and explain their major similarities. Then how to use these circuit similarities to develop electrical testing procedures to troubleshoot vehicle electrical circuits and avoid common electrical mistakes."
            url="/webinars/w-002"
          />
        </Col>
      </Row>
      <Row justifyContent="center">
        <Col width="75%">
          <p className="meta">
            Attend or watch this webinar first. Other webinars will build off of it.
          </p>
          <ProductCard
            type="secondary"
            title="Webinar 001: Clarifying a Major Electrical Misunderstanding"
            description="This misunderstanding prevents techs from enjoying electrical work and making money at it. This first webinar is a need-to-understand to benefit from Vince's future electrical webinars and success doing electrical work. You need this clarification starting out."
            url="/webinars/w-001"
          />
        </Col>
      </Row>
      <hr />
      <Accordion allowZeroExpanded={true}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>How the Webinars Work</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              To be notified of upcoming webinars in advance,{' '}
              <a
                href="https://visitor.r20.constantcontact.com/manage/optin?v=001Re2XAHrK4vlFTRaxxp_JhdFSCGwu-S1pEYKOLZEwrtaMpBVDAeq-THxG1RWiVwEpEk-yISZpMOQ%3D"
                target="_blank"
                rel="noopener noreferrer"
              >
                sign-up for our email newsletter
              </a>
              . <br />
              You may also visit this page to see all upcoming and previous webinars.
            </p>
            <p>
              Webinars of electrical training and circuit troubleshooting are no longer than 1.0
              hour in length. There will be a short time afterwards devoted to Q&amp;A.
            </p>
            <p>
              Vince will cover essential electrical-electronics training topics from his vast
              archive of electrical-electronics training materials.
            </p>
            <p>
              Invitations to attend a specific webinar are sent by email to everyone on the
              Newsletter Email List.
            </p>
            <ol>
              <li>Note the topic, date and time to watch the webinar.</li>
              <li>
                To register and pay to attend, click on the link in the invitation email announcing
                the webinar.
              </li>
              <li>
                You will receive a Registration Confirmation email before the webinar with
                information to attend the webinar.
              </li>
              <li>Then meet Vince on-line for the webinar at the appointed time.</li>
            </ol>
            <p>
              If unforeseen circumstances cause you to miss the webinar after registration, send an
              email to Vince and we will send you a link good for 7 days that allows you to view the
              webinar one time at your convenience.
            </p>
            <p>
              A handout is available for some webinars and will be sent either by email in the
              Registration Confirmation email or sometimes downloaded from this webinar web site.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>During the Webinar</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="boost">
              Let's cooperate so this webinar is enjoyable to everyone attending!
            </p>
            <ul>
              <li>Before you log into the webinar please MUTE your microphone.</li>
              <li>Please keep your microphone MUTED during the entire webinar presentation.</li>
              <li>
                If you are not asking a question during Q&amp;A please keep your microphone OFF.
              </li>
              <li>
                When you log in to attend your connectivity to the internet may be poor which
                results in poor sound quality, echos and/or garbled speech. It can happen at any
                time during the webinar and unfortunately we can't control this.
              </li>
              <li>Log off and log in again hoping for better connectivity.</li>
              <li>
                Should you experience problems that interfere with your benefiting from the webinar
                you can get a recorded copy sent to you the next day. Simply tell us what happened
                by email and ask for a link to the webinar recording.
              </li>
            </ul>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>How to print the Webinar Handout</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>Follow these directions to print your webinar handout.</p>
            <ul>
              <li>Open the attachment &amp; File - click on "Print" or type "Ctrl/P"</li>
              <li>
                Print dialogue box opens. Select these options (Recommended).
                <ol>
                  <li>Select "Print in grayscale (b/w) or Save in/toner</li>
                  <li>Pages to Print select "All"</li>
                </ol>
              </li>
              <li>
                Under Page Size and Handling:
                <ol>
                  <li>Select - "Multiple"</li>
                  <li>Page per sheet Select "2" or "4" to save paper</li>
                  <li>Page Order - Select "Vertical"</li>
                  <li>Orientation - Select "Landscape"</li>
                  <li>Click PRINT</li>
                </ol>
              </li>
            </ul>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  </Layout>
)

export default Webinars
